<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link to="/" class="d-flex align-center">
            <v-img :src="require(`@/assets/images/logos/logo.png`)" max-width="200px" alt="logo" contain class="me-0">
            </v-img>
          </router-link>
        </v-card-title>

        <v-card-text>
          <p class="mb-2" style="color: red">{{ this.snackBarMessage }}</p>
        </v-card-text>

        <!-- title -->
        <v-card-text>
          <p class="text-2x4 font-weight-semibold text--primary mb-2">
            Welcome to Our Top Up Community! 👋🏻
          </p>
          <p class="mb-2">
            Please sign-in to your account and start the adventure
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form>
            <v-text-field v-model="username" outlined label="Username" ref="username.value" placeholder="JohnDoe"
              hide-details class="mb-3">
            </v-text-field>

            <v-text-field v-model="password" outlined :type="isPasswordVisible ? 'text' : 'password'" label="Password"
              ref="password.value" placeholder="············" :append-icon="
                isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline
              " hide-details @click:append="isPasswordVisible = !isPasswordVisible"></v-text-field>

            <div class="d-flex align-center justify-space-between flex-wrap">
              <v-checkbox label="Remember Me" hide-details class="me-3 mt-1">
              </v-checkbox>

              <!-- forgot link -->
              <a href="/request_new_password" class="mt-1"> Forgot Password? </a>
            </div>
            <spinner v-if="isLoading"></spinner>
            <v-btn v-else block color="primary" class="mt-6" @click="logIn()">
              Login
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img class="auth-mask-bg" height="173" :src="
      require(`@/assets/images/misc/mask-${
        $vuetify.theme.dark ? 'dark' : 'light'
      }.png`)
    " />

    <!-- tree -->
    <v-img class="auth-tree" width="247" height="185" src="@/assets/images/misc/tree.png"></v-img>

    <!-- tree  -->
    <v-img class="auth-tree-3" width="377" height="289" src="@/assets/images/misc/tree-3.png"></v-img>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import axios from 'axios'
import store from "../../store";
import { useRouter } from '@/utils'
import { mdiEyeOutline, mdiEyeOffOutline } from "@mdi/js";
import { ref, computed } from "@vue/composition-api";
import Spinner from "@/components/Spinner.vue";

export default {
  components: {
    Spinner,
  },
  setup() {
    store.commit("initialiseStore");

    const isPasswordVisible = ref(false);
    const snackBarMessage = computed(() => store.state.snackBarMessage);
    const isLoading = computed(() => store.state.isLoading);
    const username = ref("");
    const password = ref("");

    return {
      isPasswordVisible,
      isLoading,
      snackBarMessage,
      username,
      password,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    };
  },

  mounted(){
      // executes these after the page has been mounted
      document.title = "PettyCash: TopUp | Log In"
      if(this.$store.state.isAuthenticated){
          this.$store.commit('setSnackBarMessage', "You are already logged in.")
          this.$store.commit('setSnackBarColor', "red darken-4")
          this.$store.commit('activateSnackBar', true)    
          var navigation = this.$router
          console.log(this.prevRoute)
          navigation.push({ name: 'savings.dashboard' })
      }
  },

  methods:{
      async logIn(){
          axios.defaults.headers.common['Authorization'] = ""
          localStorage.removeItem('token')

          if(this.username.length < 4){
              this.$store.commit('setSnackBarMessage', "User name is too short, user name needs to be at least 4 characters")
              this.$store.commit('setSnackBarColor', "red darken-4")
              this.$store.commit('activateSnackBar', true)
              this.$refs.username.focus()  // this causes a an auto fucos to the element
          }
          else if(this.password.length < 8){
              this.$store.commit('setSnackBarMessage', "Password is too short, needs to be at least 8 characters")
              this.$store.commit('setSnackBarColor', "red darken-4")
              this.$store.commit('activateSnackBar', true)
              this.$refs.password.focus()  // this causes a an auto fucos to the element
          }
          else {
              const formData = {
                  username: this.username,
                  password: this.password
              }

              await axios
                  .post('/api/v1/token/login/', formData)
                  .then(response => {
                      const token = response.data.auth_token
                      this.$store.commit('setToken', token)
                      axios.defaults.headers.common['Authorization'] = "Token " + token
                      localStorage.setItem('token', token)
                      var navigation = this.$router
                      this.$store.state.username = this.username

                      navigation.push({ name: 'savings.dashboard' })
                      this.$store.commit('setSnackBarMessage',"Welcome back "+this.username+" 😃")
                      this.$store.commit('setSnackBarColor', "teal darken-4")
                      this.$store.commit('activateSnackBar', true)
                  })
                  .catch(error => {
                      if (error.response){
                          for (const property in error.response.data){
                              this.$store.commit('setSnackBarMessage', error.response.data[property][0])
                              this.$store.commit('setSnackBarColor', "red darken-4")
                              this.$store.commit('activateSnackBar', true)
                          }
                      }else{
                          this.$store.commit('setSnackBarMessage', "An error occured while logging into your account")
                          this.$store.commit('setSnackBarColor', "red darken-4")
                          this.$store.commit('activateSnackBar', true)
                      }
                  })
          }

      },
  },

  components: { Spinner }
};
</script>

<style lang="scss">
@import "~@/plugins/vuetify/default-preset/preset/pages/auth.scss";
</style>
